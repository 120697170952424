import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import MainLayout from "../components/mainLayout/MainLayout"

const PoliticaDePrivacidadPage = () => {
    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        siteUrl
                    }
                }
            }
        `
    )

    const defaultTitle = site.siteMetadata?.title
    const defaultURL = site.siteMetadata?.siteUrl || ''

    return (
        <MainLayout>
            <GatsbySeo noindex={true} nofollow={true}/>
            <h1>Aviso legal</h1>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> es un blog personal de noticias, actualidad y tutoriales.
            </p>
            <p>
                Las condiciones legales aparecen aquí descritas y cada nueva fecha aquí indicada puede revelar un cambio
                de las condiciones de uso y acceso. Mediante el presente aviso legal damos cumplimiento a nuestro deber
                de información según la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del
                Comercio Electrónico y el Reglamento Europeo de Protección de Datos (RGPD).
            </p>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> es una web cuyos contenidos pertenecen al autor con el
                pseudónimo Stuff quien ofrece contenidos sobre tutoriales directamente en su web y a través de terceras
                plataformas.
            </p>
            <h2>1. Condiciones de uso</h2>
            <p>Todos los usuarios deben leer este documento de cumplimento obligatorio para acceder al sitio. El usuario
                es cualquiera que acceda a la web por cualquiera de los medios existentes en el mercado.</p>
            <h2>2. Condiciones de Acceso</h2>
            <p>
                Queda prohibido sin excepción acceder al contenido fuera de los navegadores comerciales conocidos,
                quedando prohibido analizar el código fuente así como extraer contenidos y datos. <a
                href={defaultURL}>{defaultTitle}</a> es una web que solo permite la lectura de artículos y comentar
                opiniones en relación a esos artículos.
                <a href={defaultURL}>{defaultTitle}</a> se reserva el derecho a implementar algunas herramientas que
                considere de utilidad para el usuario debiendo este hacer un uso responsable de las herramientas y
                dentro de los objetivos que aparecen en las descripciones de uso de cada herramienta.
            </p>
            <h2>3. Responsabilidad</h2>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> no se responsabiliza en ningún momento de fallos originado por
                terceros. Entre los ejemplos podríamos citar suspensiones o interrupciones temporales o definitivas del
                servicio como: causa mayor, caídas de servidores, ataques de terceros, o falta de compatibilidades de
                navegadores y software o actualizaciones para acceder a la web.
            </p>
            <h2>4. Responsabilidad de contenidos y opiniones</h2>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> tiene como finalidad contar noticias y experiencias del mundo de
                los tutoriales online. Los comentarios intentan reflejar los aspectos de la profesión.
                En ningún caso la información expuesta se pueden considerar guías válidas, ni las opiniones como
                certezas o respuestas de consultoría.
            </p>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> hace una labor periodística. En este sentido se hablan de
                prácticas desleales y que en algunos casos puede ser cuasi infracciones según la legislación de algunos
                países.
            </p>
            <p>
                El usuario entiende al entrar en la web que la finalidad de este blog no es otra que hablar de seguridad
                y prácticas del mercado publicitario o vivencias del autor y que en ningún caso se hace apología de
                ningún tipo delictivo, contra la moral o el orden público. <a href={defaultURL}>{defaultTitle}</a> busca
                informar de las prácticas y en ningún momento se hace responsable del uso que terceros hagan de la
                información.
            </p>
            <h2>5. Responsabilidad de opiniones, correos o comunicaciones recibidas</h2>
            <p>
                <a href={defaultURL}>{defaultTitle}</a> no obliga a identificarse con datos reales o protegidos por las
                leyes para interactuar en la web, por lo que se reserva el derecho a no borrar ningún contenido, cobrar
                por su eliminación o mantenerlo.
            </p>
            <p>
                Al opinar o expresar ideas en <a href={defaultURL}>{defaultTitle}</a>, estas pasarán a la propiedad
                de <a href={defaultURL}>{defaultTitle}</a> y en ningún momento generaran derecho de indemnización ni
                reconocimiento ninguno. <a href={defaultURL}>{defaultTitle}</a> respetará el autor siempre que sea una
                autoría real y demostrable. Pero se reserva el derecho a modificar los comentarios vertidos, añadiendo,
                quitando, matizando o simplemente eliminado según el criterio editorial que se estime conveniente en
                cada momento.
            </p>
            <p>
                Se prohíbe terminantemente expresar opiniones o comentarios que vayan contra las leyes o las buenas
                costumbres, y en ningún caso nos hacemos responsables de las opiniones de terceros vertidas en la idea
                de una libertad de expresión absoluta y libre en la que creemos firmemente. Pero igualmente nos
                reservamos el derecho editorial de respetar una serie de derechos y valores que quedan a nuestro propio
                criterio y que el usuario acepta sin derecho a indemnización o reclamación.
            </p>
            <p>
                Cada usuario es responsable de sus propios comentarios y no debe publicar datos protegidos propios o de
                terceros. En ningún caso <a href={defaultURL}>{defaultTitle}</a> será responsable de las opiniones
                vertidas por terceros.
            </p>
            <p>
                Respecto a correos y opiniones recibidas nos reservamos el derecho a publicarlos o hacerlos públicos
                siempre que no tengan datos protegidos y/o/u modificarlos antes de publicarlos.
            </p>
            <p>
                Si consideras que algún comentario puede dañarte a ti o a tu empresa y eres el legítimo representante
                puedes usar el formulario de contacto para explicar la cuestión. No atenderemos a quienes no puedan
                acreditar la titularidad de los derechos.
            </p>
            <p>
                Intentaremos darte una respuesta en un tiempo razonable y de forma proporcional a los esfuerzos
                materializados en este proyecto. Al ser un blog eminentemente personal no podemos garantizarte un tiempo
                de respuesta.
            </p>
            <h2>6. Limitaciones de Acceso</h2>
            <p>Nos reservamos el derecho a bloquear a usuarios, ips, países, sistemas operativos o software, web,
                servidores de forma individual o colectiva según nuestros propios criterios editoriales o de seguridad
                sin necesidad de dar explicación y derecho a indemnización ninguna.</p>
            <p>Se prohíben los accesos automatizados a la web así como alterar el código o funcionalidades directa o
                indirectamente en cualquiera de sus formas a las subcarpetas, directorios y cualquier dato que no
                aparezca accesible desde la home o página de inicio o el motor interno de búsquedas. El acceso de partes
                protegidas se considerará un ataque a la seguridad y se actuará en consecuencia.</p>
            <p><a href={defaultURL}>{defaultTitle}</a> es un proyecto de solo lectura y comentarios mediante opiniones
                siempre en texto plano y donde no se permite inyectar código o enlaces a terceras webs fuera de los
                espacios previstos e indicados.</p>
            <h2>7. Área profesional</h2>
            <p><a href={defaultURL}>{defaultTitle}</a> puede ofrecer información de terceras empresas, enlaces de
                afiliación a patrocinados, recomendaciones o sugerencias.</p>
            <p>Todos los contenidos a terceras web, empresas y particulares no dejan de ser meras opiniones y
                valoraciones.</p>
            <p>En los enlaces de afiliados a terceras web los usuarios se comprometen a entender que acceder a una
                tercera web implica conocer y aceptar sus propias políticas y normas y que no somos responsables de sus
                productos, servicios o condiciones de uso. Siendo el usuario el que debe informarse y proceder bajo su
                cuenta y riesgo. Las comisiones recibidas son siempre por publicidad y en ningún caso hacemos de
                intermediarios.</p>
            <h2>8. Productos propios</h2>
            <p>Todos los productos propios que estén en terceras webs se regirán por las indicaciones que allí se
                hagan. <a href={defaultURL}>{defaultTitle}</a> no es responsable del resto de proyectos.</p>
            <p>El usuario debe solicitar las aclaraciones, ayuda o reclamaciones dentro de las otras plataformas en las
                secciones que se indiquen respectivamente. En ningún caso asumimos responsabilidad de ningún tipo por la
                forma en la que se promocionen dentro de esta web, ni estamos obligados a responder o dar soporte
                técnico desde este sitio. Por favor, diríjase a la web o proyecto directamente.</p>
            <h2>9. Colaboraciones</h2>
            <p>Si deseas colaborar con <a href={defaultURL}>{defaultTitle}</a> las propuestas comerciales se harán bajo
                contratos individualizados y en ningún caso el aviso legal y las condiciones de esta web pueden
                considerarse como normas supletorias o principios legales de interpretación. Cada propuesta se estudiará
                de forma individual sin que quepa ningún contrato de adhesión.</p>
            <h2>10. Uso de Cookies</h2>
            <p><a href={defaultURL}>{defaultTitle}</a> usa cookies, se reserva el derecho a usarlas o no, así como a
                añadir nuevas cookies o quitarlas. Las Cookies de <a href={defaultURL}>{defaultTitle}</a> quedan aquí
                descritas y explicadas. (Al hacer click en el enlace conocerá sus funcionalidades y políticas)</p>
            <h2>11. Contenido ilegal o inmoral</h2>
            <p>Si detectas algún contenido que pueda infringir la Ley comunícanoslo en la sección de contacto. No
                verificamos el origen de contenidos o enlaces de terceras webs y por tanto no nos hacemos responsables
                directa o indirectamente, pero haremos los esfuerzos necesarios por retirarlo tras recibir la
                comunicación.</p>
            <h2>12. Protección de datos personales</h2>
            <p><a href={defaultURL}>{defaultTitle}</a> cumple con la normativa de España y dispone de un fichero de
                suscriptores y otro de clientes. Unas vez de das de baja de los boletines dejas de recibir los correos y
                tu información queda bloqueada y no accesible. Los datos de colaboraciones profesionales o currículos
                quedarán guardados y bloqueados durante el tiempo que la legislación de España establece.</p>
            <p>En el marco de sus actividades, <a href={defaultURL}>{defaultTitle}</a> dispone de la posibilidad de
                registro de usuarios para el envío de comunicaciones por correo electrónico, efectuar comentarios en el
                blog y enviar mensajes a través del formulario de contacto.</p>
            <p>El usuario mediante los actos de suscripción al blog, la realización comentarios o el formulario de
                contacto estará dando su consentimiento expreso al tratamiento de los personales proporcionados según lo
                dispuesto en la normativa de España. Los datos se guardan en bases de datos y tienes el derecho a
                informarnos sobre tu nuevo correo o a darte de baja o modificar cualquier cuestión que nos aportarás
                previamente.</p>
            <p>Los datos de carácter personal solicitados en estas actividades, quedarán incorporados a un fichero cuya
                finalidad es la comunicación de novedades relativas al sitio web de <a
                    href={defaultURL}>{defaultTitle}</a> actuando como responsable del fichero el prestador.
            </p>
            <p>Los campos marcados con asterisco son de cumplimentación obligatoria, siendo imposible realizar la
                finalidad expresada si no se aportan estos datos. Queda igualmente informado de la posibilidad de
                ejercitar los derechos que se indican en el apartado relativo a los Derechos del usuario.
            </p>
            <p>Nuestros usuarios se encuentran amparados por la normativa en relación a la Protección de Datos a nivel
                europeo y nacional. Tal y como reconoce la normativa RGPD podrás: acceder a tus datos, rectificar los
                que estimes oportunos, restringir su uso, oponerte a su uso, cancelar los datos que nos has facilitado o
                transferir a un tercero los datos que almacenamos.</p>
            <h2>13. Servidor</h2>
            <p>Por razones técnicas y de calidad de servicio, el blog <a href={defaultURL}>{defaultTitle}</a> se
                encuentra alojado en los servidores de la empresa OVH, empresa de origen francés con instalaciones que
                se ubican en este mismo país. Puedes ver aquí las condiciones de privacidad del hosting.</p>
            <h2>14. Dominio</h2>
            <p><a href={defaultURL}>{defaultTitle}</a> utiliza un dominio de EEUU. Por lo que el tráfico de datos pasa
                por ese país y se sujeta a la normativa de EEUU donde es posible interceptar el tráfico de forma legal
                sin permiso judicial. Aunque en el resto de países también se hace.</p>
            <h2>15. Ley Aplicable y Jurisdicción</h2>
            <p>Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de
                las actividades en él desarrolladas, será de aplicación la legislación de España en el fuero de Aragón,
                a la que se someten expresamente las partes.</p>
            <p>Para cualquier duda utilice el correo.</p>
        </MainLayout>
    )
}

export default PoliticaDePrivacidadPage
